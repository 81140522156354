<template>
  <amplify-authenticator>
    <!-- The rest of your app code -->
    <amplify-sign-out />
  </amplify-authenticator>
</template>

<script>
  import { components } from 'aws-amplify-vue'
  export default {
    name: 'Authenticator',
    components: { ...components },
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
